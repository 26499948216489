import { t } from 'utils/i18n';
export enum Gender {
  FEMALE = 'female',
  MALE = 'male',
  THIRD = 'third',
}

export enum Degree {
  BACHELOR_DEGREE = 'bachelor_degree',
  MASTER_DEGREE = 'master_degree',
  DOCTORATE = 'doctorate',
}

export enum Subject {
  MANDARIN = 'mandarin',
  ENGLISH = 'english',
  MATH = 'math',
  PHYSIC = 'physic',
  SOCIAL_SCIENCE = 'social_science',
  NATURAL_SCIENCE = 'natural_science',
  VIETNAMESE = 'vietnamese',
  OTHER = 'other',
}

export enum Course {
  ELEMENTARY_SCHOOL_MANDARIN = 'elementary_school_mandarin',
  MANDARIN_PHONETIC_SYMBOLS = 'mandarin_phonetic_symbols',
  ESSAY_WRITING = 'essay_writing',
  JUNIOR_HIGH_SCHOOL_MANDARIN = 'junior_high_school_mandarin',
  HIGH_SCHOOL_MANDARIN = 'high_school_mandarin',
  ENGLISH_PRONUNCIATION_A1 = 'english_pronunciation_a1',
  ENGLISH_GRAMMAR_A1 = 'english_grammar_a1',
  ENGLISH_GRAMMAR_A2 = 'english_grammar_a2',
  ENGLISH_READING_A2 = 'english_reading_a2',
  ENGLISH_READING_B2 = 'english_reading_b2',
  ENGLISH_SPEAKING_A2 = 'english_speaking_a2',
  ENGLISH_WRITING_A2 = 'english_writing_a2',
  ENGLISH_WRITING_B1 = 'english_writing_b1',
  ENGLISH_PROFICIENCY_TEST_B1 = 'english_proficiency_test_b1',
  ELEMENTARY_SCHOOL_MATH = 'elementary_school_math',
  JUNIOR_HIGH_SCHOOL_MATH = 'junior_high_school_math',
  HIGH_SCHOOL_MATH = 'high_school_math',
  ELEMENTARY_SCHOOL_SOCIAL_SCIENCE = 'elementary_school_social_science',
  JUNIOR_HIGH_SCHOOL_GEOGRAPHY = 'junior_high_school_geography',
  JUNIOR_HIGH_SCHOOL_HISTORY = 'junior_high_school_history',
  JUNIOR_HIGH_SCHOOL_CIVICS = 'junior_high_school_civics',
  HIGH_SCHOOL_GEOGRAPHY = 'high_school_geography',
  HIGH_SCHOOL_HISTORY = 'high_school_history',
  HIGH_SCHOOL_CIVICS = 'high_school_civics',
  ELEMENTARY_SCHOOL_SCIENCE = 'elementary_school_science',
  ELEMENTARY_SCHOOL_SCIENCE_EXPERIMENT = 'elementary_school_science_experiment',
  JUNIOR_HIGH_SCHOOL_BIOLOGY = 'junior_high_school_biology',
  JUNIOR_HIGH_SCHOOL_PHYSICS_AND_CHEMISTRY = 'junior_high_school_physics_and_chemistry',
  JUNIOR_HIGH_SCHOOL_EARTH_SCIENCE = 'junior_high_school_earth_science',
  HIGH_SCHOOL_EARTH_SCIENCE = 'high_school_earth_science',
  HIGH_SCHOOL_PHYSICS = 'high_school_physics',
  HIGH_SCHOOL_CHEMISTRY = 'high_school_chemistry',
  HIGH_SCHOOL_BIOLOGY = 'high_school_biology',
  MANDARIN_AS_A_FOREIGN_LANGUAGE = 'mandarin_as_a_foreign_language',

  VN_HIGH_SCHOOL_MATH_10 = 'vn_high_school_math_10',
  VN_HIGH_SCHOOL_MATH_11 = 'vn_high_school_math_11',

  VN_JUNIOR_HIGH_SCHOOL_MATH_6 = 'vn_junior_high_school_math_6',
  VN_JUNIOR_HIGH_SCHOOL_MATH_7 = 'vn_junior_high_school_math_7',
  VN_JUNIOR_HIGH_SCHOOL_MATH_8 = 'vn_junior_high_school_math_8',

  VN_ELEMENTARY_SCHOOL_MATH_2 = 'vn_elementary_school_math_2',
  VN_ELEMENTARY_SCHOOL_MATH_3 = 'vn_elementary_school_math_3',

  VN_ELEMENTARY_SCHOOL_ENGLISH_1 = 'vn_elementary_school_english_1',
  VN_ELEMENTARY_SCHOOL_ENGLISH_2 = 'vn_elementary_school_english_2',
  VN_ELEMENTARY_SCHOOL_ENGLISH_3 = 'vn_elementary_school_english_3',
  VN_ELEMENTARY_SCHOOL_ENGLISH_4 = 'vn_elementary_school_english_4',
  VN_ELEMENTARY_SCHOOL_ENGLISH_5 = 'vn_elementary_school_english_5',

  VN_JUNIOR_HIGH_SCHOOL_ENGLISH_6 = 'vn_junior_high_school_english_6',
  VN_JUNIOR_HIGH_SCHOOL_ENGLISH_7 = 'vn_junior_high_school_english_7',
  VN_JUNIOR_HIGH_SCHOOL_ENGLISH_8 = 'vn_junior_high_school_english_8',
  VN_JUNIOR_HIGH_SCHOOL_ENGLISH_9 = 'vn_junior_high_school_english_9',

  VN_HIGH_SCHOOL_ENGLISH_10 = 'vn_high_school_english_10',
  VN_HIGH_SCHOOL_ENGLISH_12 = 'vn_high_school_english_12',

  VN_ELEMENTARY_SCHOOL_VIETNAMESE_2 = 'vn_elementary_school_vietnamese_2',
  VN_ELEMENTARY_SCHOOL_VIETNAMESE_4 = 'vn_elementary_school_vietnamese_4',
  VN_ELEMENTARY_SCHOOL_VIETNAMESE_5 = 'vn_elementary_school_vietnamese_5',

  VN_JUNIOR_HIGH_SCHOOL_VIETNAMESE_6 = 'vn_junior_high_school_vietnamese_6',
  VN_JUNIOR_HIGH_SCHOOL_VIETNAMESE_7 = 'vn_junior_high_school_vietnamese_7',
  VN_JUNIOR_HIGH_SCHOOL_VIETNAMESE_8 = 'vn_junior_high_school_vietnamese_8',
  VN_JUNIOR_HIGH_SCHOOL_VIETNAMESE_9 = 'vn_junior_high_school_vietnamese_9',

  VN_HIGH_SCHOOL_VIETNAMESE_10 = 'vn_high_school_vietnamese_10',

  VN_JUNIOR_HIGH_SCHOOL_PHYSIC_6 = 'vn_junior_high_school_physic_6',
  VN_JUNIOR_HIGH_SCHOOL_PHYSIC_7 = 'vn_junior_high_school_physic_7',
  VN_JUNIOR_HIGH_SCHOOL_PHYSIC_8 = 'vn_junior_high_school_physic_8',
  VN_JUNIOR_HIGH_SCHOOL_PHYSIC_9 = 'vn_junior_high_school_physic_9',

  VN_HIGH_SCHOOL_PHYSIC_10 = 'vn_high_school_physic_10',
  VN_HIGH_SCHOOL_PHYSIC_11 = 'vn_high_school_physic_11',
  VN_HIGH_SCHOOL_PHYSIC_12 = 'vn_high_school_physic_12',
}
export enum AdditionalCourseMultiInterview {
  ESSAY_WRITING_BRONZE = 'essay_writing_bronze',
  ESSAY_WRITING_SILVER = 'essay_writing_silver',
  ESSAY_WRITING_GOLD = 'essay_writing_gold',
}

export enum TeachingEnvironment {
  ONLINE_TEACHING = 'online_teaching',
  IN_PERSON_TUTORING = 'in_person_tutoring',
  CRAM_SCHOOL = 'cram_school',
  SCHOOL = 'school',
  OTHER = 'other',
}

export enum YearsOfExperience {
  LESS_THAN_1_YEAR = 'less_than_1_year',
  FROM_1_2_YEARS = 'from_1_2_years',
  MORE_THAN_3_YEARS = 'more_than_3_years',
}

export enum EmploymentStatus {
  UNEMPLOYED = 'unemployed',
  FULL_TIME = 'full_time',
  STUDENT = 'student',
  PART_TIME = 'part_time',
}

export enum WeeklyAvailableTeachingHours {
  LESS_THAN_10_HOURS = 'less_than_10_hours',
  FROM_11_20_HOURS = 'from_11_20_hours',
  FROM_21_30_HOURS = 'from_21_30_hours',
  FROM_31_40_HOURS = 'from_31_40_hours',
  MORE_THAN_41_HOURS = 'more_than_41_hours',
}

export enum StaffType {
  IDLE = 'idle',
  INSIDE = 'inside',
  OUTSIDE = 'outside',
}

export enum TeacherStatus {
  AVAILABLE = 'available',
  UNAVAILABLE = 'unavailable',
  PAUSED = 'paused',
  UNVERIFIED = 'unverified',
}

export enum MessageType {
  COURSE_REMINDER = 'courseReminder',
  COURSE_SCHEDULE_REMINDER = 'courseScheduleReminder',
  COURSE_SCHEDULE_NOTICE = 'courseScheduleNotice',
  COURSE_CHANGE = 'courseChange',
  COURSE_SCHEDULE_REQUEST = 'courseScheduleRequest',
  TEXT = 'text',
  INTERACTIVE = 'interactive',
  COURSE_CANCEL = 'courseCancel',
}

export enum ReadStatus {
  READ = 'read',
  UNREAD = 'unread',
}

export enum InteractiveStatus {
  UNUSED = 'unused',
  CANCEL = 'cancel',
  UNAVAILABLE = 'unavailable',
  AVAILABLE = 'available',
  SCHEDULED = 'scheduled',
  WAIT_FEEDBACK = 'waitFeedback',
  INVALID = 'invalid',
  REJECT = 'reject',
}

export enum InteractiveAccept {
  UNUSED = 'unused',
  CHOOSE = 'choose',
  ACCEPT = 'accept',
  UNACCEPT = 'unaccept',
}

export enum InterviewStatus {
  INITIAL_INTERVIEW = 'initial_interview',
  EDITING = 'editing',
  RE_INTERVIEW = 're_interview',
}

export enum ReviewStatus {
  UNREVIEWED = 'unreviewed',
  REVIEWING = 'reviewing',
  PASSED = 'passed',
  FAILED = 'failed',
  RE_INTERVIEW = 're_interview',
}

export enum TeacherType {
  UNSIGNED = 'unsigned',
  SIGNED = 'signed',
  OFFICIAL = 'official',
}
export function getCourseLabel(
  t: (key: string, defaultValue: string, params?: any | null) => string,
) {
  return [
    {
      value: Course.ELEMENTARY_SCHOOL_MANDARIN,
      label: t(
        'teacherApplication.course.elementarySchoolMandarin',
        'Elementary School Mandarin',
      ),
    },
    {
      value: Course.MANDARIN_PHONETIC_SYMBOLS,
      label: t(
        'teacherApplication.course.mandarinPhoneticSymbols',
        'Mandarin Phonetic Symbols',
      ),
    },
    {
      value: Course.ESSAY_WRITING,
      label: t('teacherApplication.course.essayWriting', 'Essay Writing'),
    },
    {
      value: Course.JUNIOR_HIGH_SCHOOL_MANDARIN,
      label: t(
        'teacherApplication.course.juniorHighSchoolMandarin',
        'Junior High School Mandarin',
      ),
    },
    {
      value: Course.HIGH_SCHOOL_MANDARIN,
      label: t(
        'teacherApplication.course.highSchoolMandarin',
        'High School Mandarin',
      ),
    },
    {
      value: Course.ENGLISH_PRONUNCIATION_A1,
      label: t(
        'teacherApplication.course.englishPronunciationA1',
        'English Pronunciation A1',
      ),
    },
    {
      value: Course.ENGLISH_GRAMMAR_A1,
      label: t(
        'teacherApplication.course.englishGrammarA1',
        'English Grammar A1',
      ),
    },
    {
      value: Course.ENGLISH_GRAMMAR_A2,
      label: t(
        'teacherApplication.course.englishGrammarA2',
        'English Grammar A2',
      ),
    },
    {
      value: Course.ENGLISH_READING_A2,
      label: t(
        'teacherApplication.course.englishReadingA2',
        'English Reading A2',
      ),
    },
    {
      value: Course.ENGLISH_READING_B2,
      label: t(
        'teacherApplication.course.englishReadingB2',
        'English Reading B2',
      ),
    },
    {
      value: Course.ENGLISH_SPEAKING_A2,
      label: t(
        'teacherApplication.course.englishSpeakingA2',
        'English Speaking A2',
      ),
    },
    {
      value: Course.ENGLISH_WRITING_A2,
      label: t(
        'teacherApplication.course.englishWritingA2',
        'English Writing A2',
      ),
    },
    {
      value: Course.ENGLISH_WRITING_B1,
      label: t(
        'teacherApplication.course.englishWritingB1',
        'English Writing B1',
      ),
    },
    {
      value: Course.ENGLISH_PROFICIENCY_TEST_B1,
      label: t(
        'teacherApplication.course.englishProficiencyTestB1',
        'English Proficiency Test B1',
      ),
    },
    {
      value: Course.ELEMENTARY_SCHOOL_MATH,
      label: t(
        'teacherApplication.course.elementarySchoolMath',
        'Elementary School Math',
      ),
    },
    {
      value: Course.JUNIOR_HIGH_SCHOOL_MATH,
      label: t(
        'teacherApplication.course.juniorHighSchoolMath',
        'Junior High School Math',
      ),
    },
    {
      value: Course.HIGH_SCHOOL_MATH,
      label: t('teacherApplication.course.highSchoolMath', 'High School Math'),
    },
    {
      value: Course.ELEMENTARY_SCHOOL_SOCIAL_SCIENCE,
      label: t(
        'teacherApplication.course.elementarySchoolSocialScience',
        'Elementary School Social Science',
      ),
    },
    {
      value: Course.JUNIOR_HIGH_SCHOOL_GEOGRAPHY,
      label: t(
        'teacherApplication.course.juniorHighSchoolGeography',
        'Junior High School Geography',
      ),
    },
    {
      value: Course.JUNIOR_HIGH_SCHOOL_HISTORY,
      label: t(
        'teacherApplication.course.juniorHighSchoolHistory',
        'Junior High School History',
      ),
    },
    {
      value: Course.JUNIOR_HIGH_SCHOOL_CIVICS,
      label: t(
        'teacherApplication.course.juniorHighSchoolCivics',
        'Junior High School Civics',
      ),
    },
    {
      value: Course.HIGH_SCHOOL_GEOGRAPHY,
      label: t(
        'teacherApplication.course.highSchoolGeography',
        'High School Geography',
      ),
    },
    {
      value: Course.HIGH_SCHOOL_HISTORY,
      label: t(
        'teacherApplication.course.highSchoolHistory',
        'High School History',
      ),
    },
    {
      value: Course.HIGH_SCHOOL_CIVICS,
      label: t(
        'teacherApplication.course.highSchoolCivics',
        'High School Civics',
      ),
    },
    {
      value: Course.ELEMENTARY_SCHOOL_SCIENCE,
      label: t(
        'teacherApplication.course.elementarySchoolScience',
        'Elementary School Science',
      ),
    },
    {
      value: Course.ELEMENTARY_SCHOOL_SCIENCE_EXPERIMENT,
      label: t(
        'teacherApplication.course.elementarySchoolScienceExperiment',
        'Elementary School Science Experiment',
      ),
    },
    {
      value: Course.JUNIOR_HIGH_SCHOOL_BIOLOGY,
      label: t(
        'teacherApplication.course.juniorHighSchoolBiology',
        'Junior High School Biology',
      ),
    },
    {
      value: Course.JUNIOR_HIGH_SCHOOL_PHYSICS_AND_CHEMISTRY,
      label: t(
        'teacherApplication.course.juniorHighSchoolPhysicsAndChemistry',
        'Junior High School Physics and Chemistry',
      ),
    },
    {
      value: Course.JUNIOR_HIGH_SCHOOL_EARTH_SCIENCE,
      label: t(
        'teacherApplication.course.juniorHighSchoolEarthScience',
        'Junior High School Earth Science',
      ),
    },
    {
      value: Course.HIGH_SCHOOL_EARTH_SCIENCE,
      label: t(
        'teacherApplication.course.highSchoolEarthScience',
        'High School Earth Science',
      ),
    },
    {
      value: Course.HIGH_SCHOOL_PHYSICS,
      label: t(
        'teacherApplication.course.highSchoolPhysics',
        'High School Physics',
      ),
    },
    {
      value: Course.HIGH_SCHOOL_CHEMISTRY,
      label: t(
        'teacherApplication.course.highSchoolChemistry',
        'High School Chemistry',
      ),
    },
    {
      value: Course.HIGH_SCHOOL_BIOLOGY,
      label: t(
        'teacherApplication.course.highSchoolBiology',
        'High School Biology',
      ),
    },
    {
      value: Course.MANDARIN_AS_A_FOREIGN_LANGUAGE,
      label: t(
        'teacherApplication.course.mandarinAsForeignLanguage',
        'Mandarin as a Foreign Language',
      ),
    },
  ];
}
export function getCourseLabelFull() {
  return [
    {
      value: Course.ELEMENTARY_SCHOOL_MANDARIN,
      label: t(
        'teacherApplication.course.elementarySchoolMandarin',
        'Elementary School Mandarin',
      ),
    },
    {
      value: Course.MANDARIN_PHONETIC_SYMBOLS,
      label: t(
        'teacherApplication.course.mandarinPhoneticSymbols',
        'Mandarin Phonetic Symbols',
      ),
    },
    {
      value: Course.ESSAY_WRITING,
      label: t('teacherApplication.course.essayWriting', 'Essay Writing'),
    },
    {
      value: AdditionalCourseMultiInterview.ESSAY_WRITING_BRONZE,
      label: t('teacherApplication.course.essayWritingBronze', '作文青銅'),
    },
    {
      value: AdditionalCourseMultiInterview.ESSAY_WRITING_SILVER,
      label: t('teacherApplication.course.essayWritingSilver', '作文白銀'),
    },
    {
      value: AdditionalCourseMultiInterview.ESSAY_WRITING_GOLD,
      label: t('teacherApplication.course.essayWritingGold', '作文黃金'),
    },
    {
      value: Course.JUNIOR_HIGH_SCHOOL_MANDARIN,
      label: t(
        'teacherApplication.course.juniorHighSchoolMandarin',
        'Junior High School Mandarin',
      ),
    },
    {
      value: Course.HIGH_SCHOOL_MANDARIN,
      label: t(
        'teacherApplication.course.highSchoolMandarin',
        'High School Mandarin',
      ),
    },
    {
      value: Course.ENGLISH_PRONUNCIATION_A1,
      label: t(
        'teacherApplication.course.englishPronunciationA1',
        'English Pronunciation A1',
      ),
    },
    {
      value: Course.ENGLISH_GRAMMAR_A1,
      label: t(
        'teacherApplication.course.englishGrammarA1',
        'English Grammar A1',
      ),
    },
    {
      value: Course.ENGLISH_GRAMMAR_A2,
      label: t(
        'teacherApplication.course.englishGrammarA2',
        'English Grammar A2',
      ),
    },
    {
      value: Course.ENGLISH_READING_A2,
      label: t(
        'teacherApplication.course.englishReadingA2',
        'English Reading A2',
      ),
    },
    {
      value: Course.ENGLISH_READING_B2,
      label: t(
        'teacherApplication.course.englishReadingB2',
        'English Reading B2',
      ),
    },
    {
      value: Course.ENGLISH_SPEAKING_A2,
      label: t(
        'teacherApplication.course.englishSpeakingA2',
        'English Speaking A2',
      ),
    },
    {
      value: Course.ENGLISH_WRITING_A2,
      label: t(
        'teacherApplication.course.englishWritingA2',
        'English Writing A2',
      ),
    },
    {
      value: Course.ENGLISH_WRITING_B1,
      label: t(
        'teacherApplication.course.englishWritingB1',
        'English Writing B1',
      ),
    },
    {
      value: Course.ENGLISH_PROFICIENCY_TEST_B1,
      label: t(
        'teacherApplication.course.englishProficiencyTestB1',
        'English Proficiency Test B1',
      ),
    },
    {
      value: Course.ELEMENTARY_SCHOOL_MATH,
      label: t(
        'teacherApplication.course.elementarySchoolMath',
        'Elementary School Math',
      ),
    },
    {
      value: Course.JUNIOR_HIGH_SCHOOL_MATH,
      label: t(
        'teacherApplication.course.juniorHighSchoolMath',
        'Junior High School Math',
      ),
    },
    {
      value: Course.HIGH_SCHOOL_MATH,
      label: t('teacherApplication.course.highSchoolMath', 'High School Math'),
    },
    {
      value: Course.ELEMENTARY_SCHOOL_SOCIAL_SCIENCE,
      label: t(
        'teacherApplication.course.elementarySchoolSocialScience',
        'Elementary School Social Science',
      ),
    },
    {
      value: Course.JUNIOR_HIGH_SCHOOL_GEOGRAPHY,
      label: t(
        'teacherApplication.course.juniorHighSchoolGeography',
        'Junior High School Geography',
      ),
    },
    {
      value: Course.JUNIOR_HIGH_SCHOOL_HISTORY,
      label: t(
        'teacherApplication.course.juniorHighSchoolHistory',
        'Junior High School History',
      ),
    },
    {
      value: Course.JUNIOR_HIGH_SCHOOL_CIVICS,
      label: t(
        'teacherApplication.course.juniorHighSchoolCivics',
        'Junior High School Civics',
      ),
    },
    {
      value: Course.HIGH_SCHOOL_GEOGRAPHY,
      label: t(
        'teacherApplication.course.highSchoolGeography',
        'High School Geography',
      ),
    },
    {
      value: Course.HIGH_SCHOOL_HISTORY,
      label: t(
        'teacherApplication.course.highSchoolHistory',
        'High School History',
      ),
    },
    {
      value: Course.HIGH_SCHOOL_CIVICS,
      label: t(
        'teacherApplication.course.highSchoolCivics',
        'High School Civics',
      ),
    },
    {
      value: Course.ELEMENTARY_SCHOOL_SCIENCE,
      label: t(
        'teacherApplication.course.elementarySchoolScience',
        'Elementary School Science',
      ),
    },
    {
      value: Course.ELEMENTARY_SCHOOL_SCIENCE_EXPERIMENT,
      label: t(
        'teacherApplication.course.elementarySchoolScienceExperiment',
        'Elementary School Science Experiment',
      ),
    },
    {
      value: Course.JUNIOR_HIGH_SCHOOL_BIOLOGY,
      label: t(
        'teacherApplication.course.juniorHighSchoolBiology',
        'Junior High School Biology',
      ),
    },
    {
      value: Course.JUNIOR_HIGH_SCHOOL_PHYSICS_AND_CHEMISTRY,
      label: t(
        'teacherApplication.course.juniorHighSchoolPhysicsAndChemistry',
        'Junior High School Physics and Chemistry',
      ),
    },
    {
      value: Course.JUNIOR_HIGH_SCHOOL_EARTH_SCIENCE,
      label: t(
        'teacherApplication.course.juniorHighSchoolEarthScience',
        'Junior High School Earth Science',
      ),
    },
    {
      value: Course.HIGH_SCHOOL_EARTH_SCIENCE,
      label: t(
        'teacherApplication.course.highSchoolEarthScience',
        'High School Earth Science',
      ),
    },
    {
      value: Course.HIGH_SCHOOL_PHYSICS,
      label: t(
        'teacherApplication.course.highSchoolPhysics',
        'High School Physics',
      ),
    },
    {
      value: Course.HIGH_SCHOOL_CHEMISTRY,
      label: t(
        'teacherApplication.course.highSchoolChemistry',
        'High School Chemistry',
      ),
    },
    {
      value: Course.HIGH_SCHOOL_BIOLOGY,
      label: t(
        'teacherApplication.course.highSchoolBiology',
        'High School Biology',
      ),
    },
    {
      value: Course.MANDARIN_AS_A_FOREIGN_LANGUAGE,
      label: t(
        'teacherApplication.course.mandarinAsForeignLanguage',
        'Mandarin as a Foreign Language',
      ),
    },
    {
      value: Course.VN_JUNIOR_HIGH_SCHOOL_MATH_6,
      label: t(
        'teacherApplication.course.vnJuniorHighSchoolMath6',
        'Junior High School Math',
      ),
    },
    {
      value: Course.VN_JUNIOR_HIGH_SCHOOL_MATH_7,
      label: t(
        'teacherApplication.course.vnJuniorHighSchoolMath7',
        'Toán THCS 7',
      ),
    },
    {
      value: Course.VN_JUNIOR_HIGH_SCHOOL_MATH_8,
      label: t(
        'teacherApplication.course.vnJuniorHighSchoolMath8',
        'Toán THCS 8',
      ),
    },
    {
      value: Course.VN_ELEMENTARY_SCHOOL_VIETNAMESE_2,
      label: t(
        'teacherApplication.course.vnElementarySchoolVietnamese2',
        'Tiếng việt Tiểu học 2',
      ),
    },
    {
      value: Course.VN_ELEMENTARY_SCHOOL_VIETNAMESE_4,
      label: t(
        'teacherApplication.course.vnElementarySchoolVietnamese4',
        'Tiếng việt Tiểu học 4',
      ),
    },
    {
      value: Course.VN_ELEMENTARY_SCHOOL_VIETNAMESE_5,
      label: t(
        'teacherApplication.course.vnJuniorHighSchoolVietnamese5',
        'Tiếng việt THCS 5',
      ),
    },
    {
      value: Course.VN_JUNIOR_HIGH_SCHOOL_VIETNAMESE_6,
      label: t(
        'teacherApplication.course.vnJuniorHighSchoolVietnamese6',
        'Tiếng việt THCS 6',
      ),
    },
    {
      value: Course.VN_JUNIOR_HIGH_SCHOOL_VIETNAMESE_7,
      label: t(
        'teacherApplication.course.vnJuniorHighSchoolVietnamese7',
        'Tiếng việt THCS 7',
      ),
    },
    {
      value: Course.VN_JUNIOR_HIGH_SCHOOL_VIETNAMESE_8,
      label: t(
        'teacherApplication.course.vnJuniorHighSchoolVietnamese8',
        'Tiếng việt THCS 8',
      ),
    },
    {
      value: Course.VN_JUNIOR_HIGH_SCHOOL_VIETNAMESE_9,
      label: t(
        'teacherApplication.course.vnJuniorHighSchoolVietnamese9',
        'Tiếng việt THCS 9',
      ),
    },

    {
      value: Course.VN_HIGH_SCHOOL_VIETNAMESE_10,
      label: t(
        'teacherApplication.course.vnHighSchoolVietnamese10',
        'Ngữ văn THPT 10',
      ),
    },
    {
      value: Course.VN_ELEMENTARY_SCHOOL_ENGLISH_1,
      label: t(
        'teacherApplication.course.vnElementarySchoolEnglish1',
        'Tiếng anh tiểu học 1',
      ),
    },
    {
      value: Course.VN_ELEMENTARY_SCHOOL_ENGLISH_2,
      label: t(
        'teacherApplication.course.vnElementarySchoolEnglish2',
        'Tiếng anh tiểu học 2',
      ),
    },
    {
      value: Course.VN_ELEMENTARY_SCHOOL_ENGLISH_3,
      label: t(
        'teacherApplication.course.vnElementarySchoolEnglish3',
        'Tiếng anh tiểu học 3',
      ),
    },
    {
      value: Course.VN_ELEMENTARY_SCHOOL_ENGLISH_4,
      label: t(
        'teacherApplication.course.vnElementarySchoolEnglish4',
        'Tiếng anh tiểu học 4',
      ),
    },
    {
      value: Course.VN_ELEMENTARY_SCHOOL_ENGLISH_5,
      label: t(
        'teacherApplication.course.vnElementarySchoolEnglish5',
        'Tiếng anh tiểu học 5',
      ),
    },

    {
      value: Course.VN_JUNIOR_HIGH_SCHOOL_ENGLISH_6,
      label: t(
        'teacherApplication.course.vnJuniorHighSchoolEnglish6',
        'Tiếng anh THCS 6',
      ),
    },
    {
      value: Course.VN_JUNIOR_HIGH_SCHOOL_ENGLISH_7,
      label: t(
        'teacherApplication.course.vnJuniorHighSchoolEnglish7',
        'Tiếng anh THCS 7',
      ),
    },
    {
      value: Course.VN_JUNIOR_HIGH_SCHOOL_ENGLISH_8,
      label: t(
        'teacherApplication.course.vnJuniorHighSchoolEnglish8',
        'Tiếng anh THCS 8',
      ),
    },
    {
      value: Course.VN_JUNIOR_HIGH_SCHOOL_ENGLISH_9,
      label: t(
        'teacherApplication.course.vnJuniorHighSchoolEnglish9',
        'Tiếng anh THCS 9',
      ),
    },

    {
      value: Course.VN_HIGH_SCHOOL_ENGLISH_10,
      label: t(
        'teacherApplication.course.vnHighSchoolEnglish10',
        'Tiếng anh THPT 10',
      ),
    },
    {
      value: Course.VN_HIGH_SCHOOL_ENGLISH_12,
      label: t(
        'teacherApplication.course.vnHighSchoolEnglish12',
        'Tiếng anh THPT 12',
      ),
    },

    {
      value: Course.VN_HIGH_SCHOOL_MATH_10,
      label: t('teacherApplication.course.vnHighSchoolMath10', 'Toán THPT 10'),
    },
    {
      value: Course.VN_HIGH_SCHOOL_MATH_11,
      label: t('teacherApplication.course.vnHighSchoolMath11', 'Toán THPT 11'),
    },
    {
      value: Course.VN_ELEMENTARY_SCHOOL_MATH_2,
      label: t(
        'teacherApplication.course.vnElementarySchoolMath2',
        'Toán Tiểu học 2',
      ),
    },
    {
      value: Course.VN_ELEMENTARY_SCHOOL_MATH_3,
      label: t(
        'teacherApplication.course.vnElementarySchoolMath3',
        'Toán Tiểu học 3',
      ),
    },

    {
      value: Course.VN_JUNIOR_HIGH_SCHOOL_PHYSIC_6,
      label: t(
        'teacherApplication.course.vnJuniorHighSchoolPhysic6',
        'Vật lý THCS 6',
      ),
    },
    {
      value: Course.VN_JUNIOR_HIGH_SCHOOL_PHYSIC_7,
      label: t(
        'teacherApplication.course.vnJuniorHighSchoolPhysic7',
        'Vật lý THCS 7',
      ),
    },
    {
      value: Course.VN_JUNIOR_HIGH_SCHOOL_PHYSIC_8,
      label: t(
        'teacherApplication.course.vnJuniorHighSchoolPhysic8',
        'Vật lý THCS 8',
      ),
    },
    {
      value: Course.VN_JUNIOR_HIGH_SCHOOL_PHYSIC_9,
      label: t(
        'teacherApplication.course.vnJuniorHighSchoolPhysic9',
        'Vật lý THCS 9',
      ),
    },

    {
      value: Course.VN_HIGH_SCHOOL_PHYSIC_10,
      label: t(
        'teacherApplication.course.vnHighSchoolPhysic10',
        'Vật lý THPT 10',
      ),
    },
    {
      value: Course.VN_HIGH_SCHOOL_PHYSIC_11,
      label: t(
        'teacherApplication.course.vnHighSchoolPhysic11',
        'Vật lý THPT 11',
      ),
    },
    {
      value: Course.VN_HIGH_SCHOOL_PHYSIC_12,
      label: t(
        'teacherApplication.course.vnHighSchoolPhysic12',
        'Vật lý THPT 12',
      ),
    },
  ];
}

export enum MonthlySalaryExpectation {
  VND_LOWER_THAN_4M = 'vnd_lower_than_4m',
  VND_BETWEEN_4M_6M = 'vnd_between_4m_6m',
  VND_BETWEEN_6M_8M = 'vnd_between_6m_8m',
  VND_BETWEEN_8M_10M = 'vnd_between_8m_10m',
  VND_HIGHER_THAN_10M = 'vnd_higher_than_10m',
}

export function getWorkingStatusLabel(
  t: (key: string, defaultValue: string, params?: any | null) => string,
) {
  return [
    {
      label: t('teacherApplication.workingStatus.unemployed', 'Unemployed'),
      value: EmploymentStatus.UNEMPLOYED,
    },
    {
      label: t('teacherApplication.workingStatus.fulltime', 'Full-time'),
      value: EmploymentStatus.FULL_TIME,
    },
    {
      label: t('teacherApplication.workingStatus.student', 'Student'),
      value: EmploymentStatus.STUDENT,
    },
    {
      label: t('teacherApplication.workingStatus.partTime', 'Part-time'),
      value: EmploymentStatus.PART_TIME,
    },
  ];
}

export function getAvailableHoursPerWeekLabel(
  t: (key: string, defaultValue: string, params?: any | null) => string,
) {
  return [
    {
      value: WeeklyAvailableTeachingHours.LESS_THAN_10_HOURS,
      label: t(
        'teacherApplication.availableHoursPerWeek.lessThanTenHours',
        'Less than 10 hours',
      ),
    },
    {
      value: WeeklyAvailableTeachingHours.FROM_11_20_HOURS,
      label: t(
        'teacherApplication.availableHoursPerWeek.elevenToTwentyHours',
        '11-20 hours',
      ),
    },
    {
      value: WeeklyAvailableTeachingHours.FROM_21_30_HOURS,
      label: t(
        'teacherApplication.availableHoursPerWeek.twentyOneToThirtyHours',
        '21-30 hours',
      ),
    },
    {
      value: WeeklyAvailableTeachingHours.FROM_31_40_HOURS,
      label: t(
        'teacherApplication.availableHoursPerWeek.thirtyOneToFortyHours',
        '31-40 hours',
      ),
    },
    {
      value: WeeklyAvailableTeachingHours.MORE_THAN_41_HOURS,
      label: t(
        'teacherApplication.availableHoursPerWeek.moreThanFortyOneHours',
        'More than 41 hours',
      ),
    },
  ];
}

export function getDegreeLabel(
  t: (key: string, defaultValue: string, params?: any | null) => string,
) {
  return [
    {
      label: t('teacherApplication.degree.bachelor', 'Bachelor’s Degree'),
      value: Degree.BACHELOR_DEGREE,
    },
    {
      label: t('teacherApplication.degree.master', 'Master’s Degree'),
      value: Degree.MASTER_DEGREE,
    },
    {
      label: t('teacherApplication.degree.doctorate', 'Doctorate'),
      value: Degree.DOCTORATE,
    },
  ];
}

export function getMonthlySalaryExpectationLabel(
  t: (key: string, defaultValue: string, params?: any | null) => string,
) {
  return [
    {
      label: t(
        'teacherApplication.monthlySalaryExpectation.vnd_lower_than_4m',
        '低於 NT$ 30,000',
      ),
      value: MonthlySalaryExpectation.VND_LOWER_THAN_4M,
    },
    {
      label: t(
        'teacherApplication.monthlySalaryExpectation.vnd_between_4m_6m',
        'NT$ 30,000 - 40,000',
      ),
      value: MonthlySalaryExpectation.VND_BETWEEN_4M_6M,
    },
    {
      label: t(
        'teacherApplication.monthlySalaryExpectation.vnd_between_6m_8m',
        'NT$ 40,000 - 50,000',
      ),
      value: MonthlySalaryExpectation.VND_BETWEEN_6M_8M,
    },
    {
      label: t(
        'teacherApplication.monthlySalaryExpectation.vnd_between_8m_10m',
        'NT$ 50,000 - 60,000',
      ),
      value: MonthlySalaryExpectation.VND_BETWEEN_8M_10M,
    },
    {
      label: t(
        'teacherApplication.monthlySalaryExpectation.vnd_higher_than_10m',
        '高於 NT$ 60,000',
      ),
      value: MonthlySalaryExpectation.VND_HIGHER_THAN_10M,
    },
  ];
}

export enum TRCApiStatus {
  SUCCESS = 'SUCCESS',
}

export enum TRCApiErrorCode {
  SUCCESS = 0,
}

export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const getSubjectCourseConstant = () => {
  return [
    {
      value: Subject.ENGLISH,
      label: t('teacherApplication.subject.english', 'English'),
      courses: [
        {
          value: Course.VN_ELEMENTARY_SCHOOL_ENGLISH_1,
          label: t(
            'teacherApplication.course.vnElementarySchoolEnglish1',
            'Tiếng anh tiểu học 1',
          ),
        },
        {
          value: Course.VN_ELEMENTARY_SCHOOL_ENGLISH_2,
          label: t(
            'teacherApplication.course.vnElementarySchoolEnglish2',
            'Tiếng anh tiểu học 2',
          ),
        },
        {
          value: Course.VN_ELEMENTARY_SCHOOL_ENGLISH_3,
          label: t(
            'teacherApplication.course.vnElementarySchoolEnglish3',
            'Tiếng anh tiểu học 3',
          ),
        },
        {
          value: Course.VN_ELEMENTARY_SCHOOL_ENGLISH_4,
          label: t(
            'teacherApplication.course.vnElementarySchoolEnglish4',
            'Tiếng anh tiểu học 4',
          ),
        },
        {
          value: Course.VN_ELEMENTARY_SCHOOL_ENGLISH_5,
          label: t(
            'teacherApplication.course.vnElementarySchoolEnglish5',
            'Tiếng anh tiểu học 5',
          ),
        },
        {
          value: Course.VN_JUNIOR_HIGH_SCHOOL_ENGLISH_6,
          label: t(
            'teacherApplication.course.vnJuniorHighSchoolEnglish6',
            'Tiếng anh THCS 6',
          ),
        },
        {
          value: Course.VN_JUNIOR_HIGH_SCHOOL_ENGLISH_7,
          label: t(
            'teacherApplication.course.vnJuniorHighSchoolEnglish7',
            'Tiếng anh THCS 7',
          ),
        },
        {
          value: Course.VN_JUNIOR_HIGH_SCHOOL_ENGLISH_8,
          label: t(
            'teacherApplication.course.vnJuniorHighSchoolEnglish8',
            'Tiếng anh THCS 8',
          ),
        },
        {
          value: Course.VN_JUNIOR_HIGH_SCHOOL_ENGLISH_9,
          label: t(
            'teacherApplication.course.vnJuniorHighSchoolEnglish9',
            'Tiếng anh THCS 9',
          ),
        },

        {
          value: Course.VN_HIGH_SCHOOL_ENGLISH_10,
          label: t(
            'teacherApplication.course.vnHighSchoolEnglish10',
            'Tiếng anh THPT 10',
          ),
        },
        {
          value: Course.VN_HIGH_SCHOOL_ENGLISH_12,
          label: t(
            'teacherApplication.course.vnHighSchoolEnglish12',
            'Tiếng anh THPT 12',
          ),
        },

        /*
        {
          value: Course.ENGLISH_PRONUNCIATION_A1,
          label: t(
            'teacherApplication.course.englishPronunciationA1',
            'English Pronunciation A1',
          ),
        },
        {
          value: Course.ENGLISH_GRAMMAR_A1,
          label: t(
            'teacherApplication.course.englishGrammarA1',
            'English Grammar A1',
          ),
        },
        {
          value: Course.ENGLISH_GRAMMAR_A2,
          label: t(
            'teacherApplication.course.englishGrammarA2',
            'English Grammar A2',
          ),
        },
        {
          value: Course.ENGLISH_READING_A2,
          label: t(
            'teacherApplication.course.englishReadingA2',
            'English Reading A2',
          ),
        },
        {
          value: Course.ENGLISH_READING_B2,
          label: t(
            'teacherApplication.course.englishReadingB2',
            'English Reading B2',
          ),
        },
        {
          value: Course.ENGLISH_SPEAKING_A2,
          label: t(
            'teacherApplication.course.englishSpeakingA2',
            'English Speaking A2',
          ),
        },
        {
          value: Course.ENGLISH_WRITING_A2,
          label: t(
            'teacherApplication.course.englishWritingA2',
            'English Writing A2',
          ),
        },
        {
          value: Course.ENGLISH_WRITING_B1,
          label: t(
            'teacherApplication.course.englishWritingB1',
            'English Writing B1',
          ),
        },
        {
          value: Course.ENGLISH_PROFICIENCY_TEST_B1,
          label: t(
            'teacherApplication.course.englishProficiencyTestB1',
            'English Proficiency Test B1',
          ),
        },
        */
      ],
    },
    {
      value: Subject.MATH,
      label: t('teacherApplication.subject.math', 'Math'),
      courses: [
        {
          value: Course.VN_HIGH_SCHOOL_MATH_10,
          label: t(
            'teacherApplication.course.vnHighSchoolMath10',
            'Toán THPT 10',
          ),
        },
        {
          value: Course.VN_HIGH_SCHOOL_MATH_11,
          label: t(
            'teacherApplication.course.vnHighSchoolMath11',
            'Toán THPT 11',
          ),
        },

        {
          value: Course.VN_JUNIOR_HIGH_SCHOOL_MATH_6,
          label: t(
            'teacherApplication.course.vnJuniorHighSchoolMath6',
            'Toán THCS 6',
          ),
        },
        {
          value: Course.VN_JUNIOR_HIGH_SCHOOL_MATH_7,
          label: t(
            'teacherApplication.course.vnJuniorHighSchoolMath7',
            'Toán THCS 7',
          ),
        },
        {
          value: Course.VN_JUNIOR_HIGH_SCHOOL_MATH_8,
          label: t(
            'teacherApplication.course.vnJuniorHighSchoolMath8',
            'Toán THCS 8',
          ),
        },

        {
          value: Course.VN_ELEMENTARY_SCHOOL_MATH_2,
          label: t(
            'teacherApplication.course.vnElementarySchoolMath2',
            'Toán Tiểu học 2',
          ),
        },
        {
          value: Course.VN_ELEMENTARY_SCHOOL_MATH_3,
          label: t(
            'teacherApplication.course.vnElementarySchoolMath3',
            'Toán Tiểu học 3',
          ),
        },
        /*
        {
          value: Course.ELEMENTARY_SCHOOL_MATH,
          label: t(
            'teacherApplication.course.elementarySchoolMath',
            'Elementary School Math',
          ),
        },
        {
          value: Course.JUNIOR_HIGH_SCHOOL_MATH,
          label: t(
            'teacherApplication.course.juniorHighSchoolMath',
            'Junior High School Math',
          ),
        },
        {
          value: Course.HIGH_SCHOOL_MATH,
          label: t(
            'teacherApplication.course.highSchoolMath',
            'High School Math',
          ),
        },
        },
      ],
    },
    {
      value: Subject.SOCIAL_SCIENCE,
      label: t('teacherApplication.subject.socialScience', 'Social Science'),
      courses: [
        {
          value: Course.ELEMENTARY_SCHOOL_SOCIAL_SCIENCE,
          label: t(
            'teacherApplication.course.elementarySchoolSocialScience',
            'Elementary School Social Science',
          ),
        },
        {
          value: Course.JUNIOR_HIGH_SCHOOL_GEOGRAPHY,
          label: t(
            'teacherApplication.course.juniorHighSchoolGeography',
            'Junior High School Geography',
          ),
        },
        {
          value: Course.JUNIOR_HIGH_SCHOOL_HISTORY,
          label: t(
            'teacherApplication.course.juniorHighSchoolHistory',
            'Junior High School History',
          ),
        },
        {
          value: Course.JUNIOR_HIGH_SCHOOL_CIVICS,
          label: t(
            'teacherApplication.course.juniorHighSchoolCivics',
            'Junior High School Civics',
          ),
        },
        {
          value: Course.HIGH_SCHOOL_GEOGRAPHY,
          label: t(
            'teacherApplication.course.highSchoolGeography',
            'High School Geography',
          ),
        },
        {
          value: Course.HIGH_SCHOOL_HISTORY,
          label: t(
            'teacherApplication.course.highSchoolHistory',
            'High School History',
          ),
        },
        {
          value: Course.HIGH_SCHOOL_CIVICS,
          label: t(
            'teacherApplication.course.highSchoolCivics',
            'High School Civics',
          ),
        },
      ],
    },
    {
      value: Subject.NATURAL_SCIENCE,
      label: t('teacherApplication.subject.naturalScience', 'Natural Science'),
      courses: [
        {
          value: Course.ELEMENTARY_SCHOOL_SCIENCE,
          label: t(
            'teacherApplication.course.elementarySchoolScience',
            'Elementary School Science',
          ),
        },
        {
          value: Course.ELEMENTARY_SCHOOL_SCIENCE_EXPERIMENT,
          label: t(
            'teacherApplication.course.elementarySchoolScienceExperiment',
            'Elementary School Science Experiment',
          ),
        },
        {
          value: Course.JUNIOR_HIGH_SCHOOL_BIOLOGY,
          label: t(
            'teacherApplication.course.juniorHighSchoolBiology',
            'Junior High School Biology',
          ),
        },
        {
          value: Course.JUNIOR_HIGH_SCHOOL_PHYSICS_AND_CHEMISTRY,
          label: t(
            'teacherApplication.course.juniorHighSchoolPhysicsAndChemistry',
            'Junior High School Physics and Chemistry',
          ),
        },
        {
          value: Course.JUNIOR_HIGH_SCHOOL_EARTH_SCIENCE,
          label: t(
            'teacherApplication.course.juniorHighSchoolEarthScience',
            'Junior High School Earth Science',
          ),
        },
        {
          value: Course.HIGH_SCHOOL_EARTH_SCIENCE,
          label: t(
            'teacherApplication.course.highSchoolEarthScience',
            'High School Earth Science',
          ),
        },
        {
          value: Course.HIGH_SCHOOL_PHYSICS,
          label: t(
            'teacherApplication.course.highSchoolPhysics',
            'High School Physics',
          ),
        },
        {
          value: Course.HIGH_SCHOOL_CHEMISTRY,
          label: t(
            'teacherApplication.course.highSchoolChemistry',
            'High School Chemistry',
          ),
        },
        {
          value: Course.HIGH_SCHOOL_BIOLOGY,
          label: t(
            'teacherApplication.course.highSchoolBiology',
            'High School Biology',
          ),
        },
        */
      ],
    },

    {
      value: Subject.VIETNAMESE,
      label: t('teacherApplication.subject.vietnamese', 'Tiếng việt'),
      courses: [
        {
          value: Course.VN_ELEMENTARY_SCHOOL_VIETNAMESE_2,
          label: t(
            'teacherApplication.course.vnElementarySchoolVietnamese2',
            'Tiếng việt Tiểu học 2',
          ),
        },
        {
          value: Course.VN_ELEMENTARY_SCHOOL_VIETNAMESE_4,
          label: t(
            'teacherApplication.course.vnElementarySchoolVietnamese4',
            'Tiếng việt Tiểu học 4',
          ),
        },
        {
          value: Course.VN_ELEMENTARY_SCHOOL_VIETNAMESE_5,
          label: t(
            'teacherApplication.course.vnElementarySchoolVietnamese5',
            'Tiếng việt Tiểu học 5',
          ),
        },

        {
          value: Course.VN_JUNIOR_HIGH_SCHOOL_VIETNAMESE_6,
          label: t(
            'teacherApplication.course.vnJuniorHighSchoolVietnamese6',
            'Tiếng việt THCS 6',
          ),
        },
        {
          value: Course.VN_JUNIOR_HIGH_SCHOOL_VIETNAMESE_7,
          label: t(
            'teacherApplication.course.vnJuniorHighSchoolVietnamese7',
            'Tiếng việt THCS 7',
          ),
        },
        {
          value: Course.VN_JUNIOR_HIGH_SCHOOL_VIETNAMESE_8,
          label: t(
            'teacherApplication.course.vnJuniorHighSchoolVietnamese8',
            'Tiếng việt THCS 8',
          ),
        },
        {
          value: Course.VN_JUNIOR_HIGH_SCHOOL_VIETNAMESE_9,
          label: t(
            'teacherApplication.course.vnJuniorHighSchoolVietnamese9',
            'Tiếng việt THCS 9',
          ),
        },

        {
          value: Course.VN_HIGH_SCHOOL_VIETNAMESE_10,
          label: t(
            'teacherApplication.course.vnHighSchoolVietnamese10',
            'Ngữ văn THPT 10',
          ),
        },
      ],
    },
    {
      value: Subject.PHYSIC,
      label: t('teacherApplication.subject.physic', 'Vật lý'),
      courses: [
        {
          value: Course.VN_JUNIOR_HIGH_SCHOOL_PHYSIC_6,
          label: t(
            'teacherApplication.course.vnJuniorHighSchoolPhysic6',
            'Vật lý THCS 6',
          ),
        },
        {
          value: Course.VN_JUNIOR_HIGH_SCHOOL_PHYSIC_7,
          label: t(
            'teacherApplication.course.vnJuniorHighSchoolPhysic7',
            'Vật lý THCS 7',
          ),
        },
        {
          value: Course.VN_JUNIOR_HIGH_SCHOOL_PHYSIC_8,
          label: t(
            'teacherApplication.course.vnJuniorHighSchoolPhysic8',
            'Vật lý THCS 8',
          ),
        },
        {
          value: Course.VN_JUNIOR_HIGH_SCHOOL_PHYSIC_9,
          label: t(
            'teacherApplication.course.vnJuniorHighSchoolPhysic9',
            'Vật lý THCS 9',
          ),
        },

        {
          value: Course.VN_HIGH_SCHOOL_PHYSIC_10,
          label: t(
            'teacherApplication.course.vnHighSchoolPhysic10',
            'Vật lý THPT 10',
          ),
        },
        {
          value: Course.VN_HIGH_SCHOOL_PHYSIC_11,
          label: t(
            'teacherApplication.course.vnHighSchoolPhysic11',
            'Vật lý THPT 11',
          ),
        },
        {
          value: Course.VN_HIGH_SCHOOL_PHYSIC_12,
          label: t(
            'teacherApplication.course.vnHighSchoolPhysic12',
            'Vật lý THPT 12',
          ),
        },
      ],
    },

    /*
    {
      value: Subject.OTHER,
      label: t('teacherApplication.subject.other', 'Other'),
      courses: [
        {
          value: Course.MANDARIN_AS_A_FOREIGN_LANGUAGE,
          label: t(
            'teacherApplication.course.mandarinAsForeignLanguage',
            'Mandarin as a Foreign Language',
          ),
        },
      ],
    },
     */
  ];
};
export const getSubjectCourseConstantForMultiInterview = () => {
  return [
    {
      value: Subject.MANDARIN,
      label: t('teacherApplication.subject.mandarin', 'Mandarin'),
      courses: [
        {
          value: Course.ELEMENTARY_SCHOOL_MANDARIN,
          label: t(
            'teacherApplication.course.elementarySchoolMandarin',
            'Elementary School Mandarin',
          ),
        },
        {
          value: Course.MANDARIN_PHONETIC_SYMBOLS,
          label: t(
            'teacherApplication.course.mandarinPhoneticSymbols',
            'Mandarin Phonetic Symbols',
          ),
        },
        {
          value: AdditionalCourseMultiInterview.ESSAY_WRITING_BRONZE,
          label: t('teacherApplication.course.essayWritingBronze', '作文青銅'),
        },
        {
          value: AdditionalCourseMultiInterview.ESSAY_WRITING_SILVER,
          label: t('teacherApplication.course.essayWritingSilver', '作文白銀'),
        },
        {
          value: AdditionalCourseMultiInterview.ESSAY_WRITING_GOLD,
          label: t('teacherApplication.course.essayWritingGold', '作文黃金'),
        },
        {
          value: Course.JUNIOR_HIGH_SCHOOL_MANDARIN,
          label: t(
            'teacherApplication.course.juniorHighSchoolMandarin',
            'Junior High School Mandarin',
          ),
        },
        {
          value: Course.HIGH_SCHOOL_MANDARIN,
          label: t(
            'teacherApplication.course.highSchoolMandarin',
            'High School Mandarin',
          ),
        },
      ],
    },
    {
      value: Subject.ENGLISH,
      label: t('teacherApplication.subject.english', 'English'),
      courses: [
        {
          value: Course.ENGLISH_PRONUNCIATION_A1,
          label: t(
            'teacherApplication.course.englishPronunciationA1',
            'English Pronunciation A1',
          ),
        },
        {
          value: Course.ENGLISH_GRAMMAR_A1,
          label: t(
            'teacherApplication.course.englishGrammarA1',
            'English Grammar A1',
          ),
        },
        {
          value: Course.ENGLISH_GRAMMAR_A2,
          label: t(
            'teacherApplication.course.englishGrammarA2',
            'English Grammar A2',
          ),
        },
        {
          value: Course.ENGLISH_READING_A2,
          label: t(
            'teacherApplication.course.englishReadingA2',
            'English Reading A2',
          ),
        },
        {
          value: Course.ENGLISH_READING_B2,
          label: t(
            'teacherApplication.course.englishReadingB2',
            'English Reading B2',
          ),
        },
        {
          value: Course.ENGLISH_SPEAKING_A2,
          label: t(
            'teacherApplication.course.englishSpeakingA2',
            'English Speaking A2',
          ),
        },
        {
          value: Course.ENGLISH_WRITING_A2,
          label: t(
            'teacherApplication.course.englishWritingA2',
            'English Writing A2',
          ),
        },
        {
          value: Course.ENGLISH_WRITING_B1,
          label: t(
            'teacherApplication.course.englishWritingB1',
            'English Writing B1',
          ),
        },
        {
          value: Course.ENGLISH_PROFICIENCY_TEST_B1,
          label: t(
            'teacherApplication.course.englishProficiencyTestB1',
            'English Proficiency Test B1',
          ),
        },
      ],
    },
    {
      value: Subject.MATH,
      label: t('teacherApplication.subject.math', 'Math'),
      courses: [
        {
          value: Course.ELEMENTARY_SCHOOL_MATH,
          label: t(
            'teacherApplication.course.elementarySchoolMath',
            'Elementary School Math',
          ),
        },
        {
          value: Course.JUNIOR_HIGH_SCHOOL_MATH,
          label: t(
            'teacherApplication.course.juniorHighSchoolMath',
            'Junior High School Math',
          ),
        },
        {
          value: Course.HIGH_SCHOOL_MATH,
          label: t(
            'teacherApplication.course.highSchoolMath',
            'High School Math',
          ),
        },
      ],
    },
    {
      value: Subject.SOCIAL_SCIENCE,
      label: t('teacherApplication.subject.socialScience', 'Social Science'),
      courses: [
        {
          value: Course.ELEMENTARY_SCHOOL_SOCIAL_SCIENCE,
          label: t(
            'teacherApplication.course.elementarySchoolSocialScience',
            'Elementary School Social Science',
          ),
        },
        {
          value: Course.JUNIOR_HIGH_SCHOOL_GEOGRAPHY,
          label: t(
            'teacherApplication.course.juniorHighSchoolGeography',
            'Junior High School Geography',
          ),
        },
        {
          value: Course.JUNIOR_HIGH_SCHOOL_HISTORY,
          label: t(
            'teacherApplication.course.juniorHighSchoolHistory',
            'Junior High School History',
          ),
        },
        {
          value: Course.JUNIOR_HIGH_SCHOOL_CIVICS,
          label: t(
            'teacherApplication.course.juniorHighSchoolCivics',
            'Junior High School Civics',
          ),
        },
        {
          value: Course.HIGH_SCHOOL_GEOGRAPHY,
          label: t(
            'teacherApplication.course.highSchoolGeography',
            'High School Geography',
          ),
        },
        {
          value: Course.HIGH_SCHOOL_HISTORY,
          label: t(
            'teacherApplication.course.highSchoolHistory',
            'High School History',
          ),
        },
        {
          value: Course.HIGH_SCHOOL_CIVICS,
          label: t(
            'teacherApplication.course.highSchoolCivics',
            'High School Civics',
          ),
        },
      ],
    },
    {
      value: Subject.NATURAL_SCIENCE,
      label: t('teacherApplication.subject.naturalScience', 'Natural Science'),
      courses: [
        {
          value: Course.ELEMENTARY_SCHOOL_SCIENCE,
          label: t(
            'teacherApplication.course.elementarySchoolScience',
            'Elementary School Science',
          ),
        },
        {
          value: Course.ELEMENTARY_SCHOOL_SCIENCE_EXPERIMENT,
          label: t(
            'teacherApplication.course.elementarySchoolScienceExperiment',
            'Elementary School Science Experiment',
          ),
        },
        {
          value: Course.JUNIOR_HIGH_SCHOOL_BIOLOGY,
          label: t(
            'teacherApplication.course.juniorHighSchoolBiology',
            'Junior High School Biology',
          ),
        },
        {
          value: Course.JUNIOR_HIGH_SCHOOL_PHYSICS_AND_CHEMISTRY,
          label: t(
            'teacherApplication.course.juniorHighSchoolPhysicsAndChemistry',
            'Junior High School Physics and Chemistry',
          ),
        },
        {
          value: Course.JUNIOR_HIGH_SCHOOL_EARTH_SCIENCE,
          label: t(
            'teacherApplication.course.juniorHighSchoolEarthScience',
            'Junior High School Earth Science',
          ),
        },
        {
          value: Course.HIGH_SCHOOL_EARTH_SCIENCE,
          label: t(
            'teacherApplication.course.highSchoolEarthScience',
            'High School Earth Science',
          ),
        },
        {
          value: Course.HIGH_SCHOOL_PHYSICS,
          label: t(
            'teacherApplication.course.highSchoolPhysics',
            'High School Physics',
          ),
        },
        {
          value: Course.HIGH_SCHOOL_CHEMISTRY,
          label: t(
            'teacherApplication.course.highSchoolChemistry',
            'High School Chemistry',
          ),
        },
        {
          value: Course.HIGH_SCHOOL_BIOLOGY,
          label: t(
            'teacherApplication.course.highSchoolBiology',
            'High School Biology',
          ),
        },
      ],
    },
    {
      value: Subject.OTHER,
      label: t('teacherApplication.subject.other', 'Other'),
      courses: [
        {
          value: Course.MANDARIN_AS_A_FOREIGN_LANGUAGE,
          label: t(
            'teacherApplication.course.mandarinAsForeignLanguage',
            'Mandarin as a Foreign Language',
          ),
        },
      ],
    },
  ];
};

export enum TeacherOrigin {
  UNOFFICIAL_NEW_TEACHER = 'UNOFFICIAL_NEW_TEACHER',
  OFFICIAL_NEW_TEACHER = 'OFFICIAL_NEW_TEACHER',
  OFFICIAL_OLD_TEACHER = 'OFFICIAL_OLD_TEACHER',
}

export const COURSE_BOOK_ID = [
  {
    key: Course.ELEMENTARY_SCHOOL_MANDARIN,
    value: '38bc3224dc9ca321',
  },
  {
    key: Course.MANDARIN_PHONETIC_SYMBOLS,
    value: '7654b5b96755f93b',
  },
  {
    key: Course.ESSAY_WRITING,
    value: 'dc595d419d78872c',
  },
  {
    key: Course.JUNIOR_HIGH_SCHOOL_MANDARIN,
    value: '24f868064c5286b9',
  },
  {
    key: Course.HIGH_SCHOOL_MANDARIN,
    value: '22bb4497296cdcf4',
  },
  {
    key: Course.ENGLISH_PRONUNCIATION_A1,
    value: 'b35bce2c906e2209',
  },
  {
    key: Course.ENGLISH_GRAMMAR_A1,
    value: 'b35bce2c906e2209',
  },
  {
    key: Course.ENGLISH_GRAMMAR_A2,
    value: 'b35bce2c906e2209',
  },
  {
    key: Course.ENGLISH_READING_A2,
    value: 'b35bce2c906e2209',
  },
  {
    key: Course.ENGLISH_READING_B2,
    value: 'b35bce2c906e2209',
  },
  {
    key: Course.ENGLISH_SPEAKING_A2,
    value: 'b35bce2c906e2209',
  },
  {
    key: Course.ENGLISH_WRITING_A2,
    value: 'b35bce2c906e2209',
  },
  {
    key: Course.ENGLISH_WRITING_B1,
    value: 'b35bce2c906e2209',
  },
  {
    key: Course.ENGLISH_PROFICIENCY_TEST_B1,
    value: 'b35bce2c906e2209',
  },

  {
    key: Course.ELEMENTARY_SCHOOL_MATH,
    value: '31f873df20cae718',
  },
  {
    key: Course.JUNIOR_HIGH_SCHOOL_MATH,
    value: '7b297070a6b300cc',
  },
  {
    key: Course.HIGH_SCHOOL_MATH,
    value: 'd907963efd4a5224',
  },

  {
    key: Course.ELEMENTARY_SCHOOL_SOCIAL_SCIENCE,
    value: 'd4a96600bd5861bb',
  },
  {
    key: Course.JUNIOR_HIGH_SCHOOL_GEOGRAPHY,
    value: '80eea8d8a3439ca0',
  },
  {
    key: Course.JUNIOR_HIGH_SCHOOL_HISTORY,
    value: '48b9acf7cc9b81b1',
  },
  {
    key: Course.JUNIOR_HIGH_SCHOOL_CIVICS,
    value: '3b2ef892100b8d58',
  },
  {
    key: Course.HIGH_SCHOOL_GEOGRAPHY,
    value: 'bc2b538264e6aa43',
  },
  {
    key: Course.HIGH_SCHOOL_HISTORY,
    value: 'a246aad1bcbf79b7',
  },
  {
    key: Course.HIGH_SCHOOL_CIVICS,
    value: '4672b5a91be19c61',
  },
  {
    key: Course.ELEMENTARY_SCHOOL_SCIENCE,
    value: 'c7bb25cf02476b8e',
  },
  {
    key: Course.ELEMENTARY_SCHOOL_SCIENCE_EXPERIMENT,
    value: 'e2420e1680a8ed67',
  },
  {
    key: Course.JUNIOR_HIGH_SCHOOL_BIOLOGY,
    value: '422abf0510fc166c',
  },
  {
    key: Course.JUNIOR_HIGH_SCHOOL_PHYSICS_AND_CHEMISTRY,
    value: 'd246edb6b6f70a0a',
  },
  {
    key: Course.JUNIOR_HIGH_SCHOOL_EARTH_SCIENCE,
    value: '2c4861c3b6195158',
  },
  {
    key: Course.HIGH_SCHOOL_EARTH_SCIENCE,
    value: '6f4c3f12dce473f0',
  },
  {
    key: Course.HIGH_SCHOOL_PHYSICS,
    value: '069ab3b084ed1ccf',
  },
  {
    key: Course.HIGH_SCHOOL_CHEMISTRY,
    value: 'd7b80404e128c241',
  },
  {
    key: Course.HIGH_SCHOOL_BIOLOGY,
    value: 'b76261e142c72ca0',
  },
  {
    key: Course.MANDARIN_AS_A_FOREIGN_LANGUAGE,
    value: '2f1da6931f561157',
  },
  {
    key: AdditionalCourseMultiInterview.ESSAY_WRITING_BRONZE,
    value: '62d6cf9b161a5417',
  },
  {
    key: AdditionalCourseMultiInterview.ESSAY_WRITING_SILVER,
    value: '5545d5045a57c32e',
  },
  {
    key: AdditionalCourseMultiInterview.ESSAY_WRITING_GOLD,
    value: '7c3ed1fd12d9cd9a',
  },

  {
    key: Course.VN_HIGH_SCHOOL_MATH_10,
    value: 'ee7adc6660274a3c',
  },
  {
    key: Course.VN_HIGH_SCHOOL_MATH_11,
    value: 'fa59524cefd30c3b',
  },

  {
    key: Course.VN_JUNIOR_HIGH_SCHOOL_MATH_6,
    value: '88f1536a48cef697',
  },
  {
    key: Course.VN_JUNIOR_HIGH_SCHOOL_MATH_7,
    value: '4a667252eec38b08',
  },
  {
    key: Course.VN_JUNIOR_HIGH_SCHOOL_MATH_8,
    value: 'ddb17ecf95ab7c83',
  },

  {
    key: Course.VN_ELEMENTARY_SCHOOL_MATH_2,
    value: '2d844c893b16d14b',
  },
  {
    key: Course.VN_ELEMENTARY_SCHOOL_MATH_3,
    value: 'fe7b6d60370e4fdd',
  },

  {
    key: Course.VN_ELEMENTARY_SCHOOL_ENGLISH_1,
    value: '98f5017af8178f4d',
  },
  {
    key: Course.VN_ELEMENTARY_SCHOOL_ENGLISH_2,
    value: 'c051e3bbbd2dd288',
  },
  {
    key: Course.VN_ELEMENTARY_SCHOOL_ENGLISH_3,
    value: '21ee4ad5f29c7fa5',
  },
  {
    key: Course.VN_ELEMENTARY_SCHOOL_ENGLISH_4,
    value: '6b0e4c1adfedcd9b',
  },
  {
    key: Course.VN_ELEMENTARY_SCHOOL_ENGLISH_5,
    value: '5ffa7aa2ab9588d0',
  },

  {
    key: Course.VN_JUNIOR_HIGH_SCHOOL_ENGLISH_6,
    value: 'f9184ae97d8aa871',
  },
  {
    key: Course.VN_JUNIOR_HIGH_SCHOOL_ENGLISH_7,
    value: '59a8b5d65d95eed3',
  },
  {
    key: Course.VN_JUNIOR_HIGH_SCHOOL_ENGLISH_8,
    value: '8c3d5cecd06023f4',
  },
  {
    key: Course.VN_JUNIOR_HIGH_SCHOOL_ENGLISH_9,
    value: '479eeaf2169fe64d',
  },

  {
    key: Course.VN_HIGH_SCHOOL_ENGLISH_10,
    value: 'c9bc517b7196508c',
  },

  {
    key: Course.VN_HIGH_SCHOOL_ENGLISH_12,
    value: 'f9a54adcd475a953',
  },

  {
    key: Course.VN_ELEMENTARY_SCHOOL_VIETNAMESE_2,
    value: 'd94dcf95e1e9a839',
  },
  {
    key: Course.VN_ELEMENTARY_SCHOOL_VIETNAMESE_4,
    value: '2c4fd1081359eeee',
  },
  {
    key: Course.VN_ELEMENTARY_SCHOOL_VIETNAMESE_5,
    value: '71222581ff81dd84',
  },

  {
    key: Course.VN_JUNIOR_HIGH_SCHOOL_VIETNAMESE_6,
    value: '13655c9c344d8d1a',
  },
  {
    key: Course.VN_JUNIOR_HIGH_SCHOOL_VIETNAMESE_7,
    value: '8020fa9c21787596',
  },
  {
    key: Course.VN_JUNIOR_HIGH_SCHOOL_VIETNAMESE_8,
    value: '1cc24d57adbcb474',
  },
  {
    key: Course.VN_JUNIOR_HIGH_SCHOOL_VIETNAMESE_9,
    value: '61183704fa987fb1',
  },
  {
    key: Course.VN_HIGH_SCHOOL_VIETNAMESE_10,
    value: 'fea6134ecdaf3415',
  },

  {
    key: Course.VN_JUNIOR_HIGH_SCHOOL_PHYSIC_6,
    value: 'bf235234980773c0',
  },
  {
    key: Course.VN_JUNIOR_HIGH_SCHOOL_PHYSIC_7,
    value: '0066cfa2f7553716',
  },
  {
    key: Course.VN_JUNIOR_HIGH_SCHOOL_PHYSIC_8,
    value: '36ec41a3af2d4034',
  },
  {
    key: Course.VN_JUNIOR_HIGH_SCHOOL_PHYSIC_9,
    value: 'd746d09feea1301f',
  },
  {
    key: Course.VN_HIGH_SCHOOL_PHYSIC_10,
    value: 'c5baa1350e676865',
  },
  {
    key: Course.VN_HIGH_SCHOOL_PHYSIC_11,
    value: '2d2fc031e2bea522',
  },
  {
    key: Course.VN_HIGH_SCHOOL_PHYSIC_12,
    value: 'b93b68be4fea3bc3',
  },
];
